

// System settings
/* eslint-enable */
/* eslint-disable */
window.addEventListener('resize', function () {
    if (window.innerWidth > 1000) {
        location.reload();
    }
})

// Burger functionality

const burgerButton = document.querySelector('#burger-button');
const burgerMenu = document.querySelector('#header-burger-menu');

burgerButton.addEventListener('click', toggleBurgerMenu)

let isBurgerOpened = false

function toggleBurgerMenu() {
    burgerButton.removeEventListener('click', toggleBurgerMenu)

    if (!isBurgerOpened) {
        gsap.set('body', {
            overflow: 'hidden',
            duration: 0
        })

        gsap.to('#burger-button-open', {
            opacity: 0,
            duration: 0.8,

        })

        gsap.to('#burger-button-close', {
            opacity: 1,
            duration: 0.8,

        })

        gsap.to(burgerMenu, {
            top: 0,
            duration: 0.8,
            ease: 'linear'
        })


        gsap.to(burgerMenu.querySelector(".header-burger-menu__background"), {
            backgroundColor: '#000000',
            duration: 0.5,
            ease: 'linear',
            delay: 0.8
        })
    } else {
        gsap.set('body', {
            overflow: 'auto',
            duration: 0
        })
        gsap.to('#burger-button-open', {
            opacity: 1,
            duration: 0.8,
        })

        gsap.to('#burger-button-close', {
            opacity: 0,
            duration: 0.8,
        })
        gsap.to(burgerMenu, {
            top: '-150%',
            duration: 0.8,
            ease: 'linear',
            delay: 0.5
        })

        gsap.to(burgerMenu.querySelector(".header-burger-menu__background"), {
            backgroundColor: 'transparent',
            duration: 0.5,
            ease: 'linear',
        })
    }

    isBurgerOpened = !isBurgerOpened

    setTimeout(() => {
        burgerButton.addEventListener('click', toggleBurgerMenu)
    }, 1300);
}





const mainBlockContact = document.querySelector('.main-block__contact')
const mainHeaderContact = document.querySelector('.header__contact')

mainBlockContact.style.right = `${(window.innerWidth - document.querySelector('.container-header').offsetWidth) / 2}px`

ScrollTrigger.create({
    trigger: ".main-block",
    start: "top top",
    // end: "bottom top",
    onToggle: (self) => {
        const scrollY = window.scrollY;
        const contactBlock = document.querySelector(".main-block__contact");

        if (scrollY < 200) {
            gsap.to(contactBlock, {
                opacity: 0,
                duration: 0.3
            })

            gsap.to(mainHeaderContact, {
                opacity: 1,
                duration: 0.3
            })

            gsap.to('.header', {
                backgroundColor: 'rgba(255, 255, 255,1)',
            })

            gsap.to('#header__main-logo', {
                opacity: 1
            })

            gsap.to('#header__main-logo-transparent', {
                opacity: 0
            })
        } else {
            gsap.to(contactBlock, {
                opacity: 1,
                duration: 0.3
            })

            gsap.to(mainHeaderContact, {
                opacity: 0,
                duration: 0.3
            })

            gsap.to('.header', {
                backgroundColor: 'rgba(255, 255, 255,0.5)',
            })

            gsap.to('#header__main-logo', {
                opacity: 0
            })

            gsap.to('#header__main-logo-transparent', {
                opacity: 1
            })
        }
    },
});


// Start animation
let startAnimationSvg = document.querySelector('.main-block__animation')
let mainBlock = document.querySelector('.main-block')
let mainBlockWrapper = document.querySelector('.main-block__wrapper')
let mainBlockVideo = document.querySelector('.main-block video')
let content = document.querySelector('.content')
let body = document.querySelector('body')


let startTl = gsap.timeline({ delay: 1 }).timeScale(1)

startTl.to(startAnimationSvg, {
    opacity: 1,
    duration: 1
})


window.addEventListener('load', () => {

    if (window.innerWidth <= 900) {
        gsap.set(mainBlock, {
            height: 233 * window.innerWidth / 414,
            top: '50vh',
            transform: 'translateY(-50%)'
        })
        startTl.to(startAnimationSvg, {
            scale: 1,
            duration: 1.5,
            ease: 'power1.inOut'
        })

        startTl.to(mainBlockVideo, {
            opacity: 1,
            duration: 1,
            onComplete: () => {
                document.querySelector(".main-block video").play();
            }
        }, '>')

        startTl.to(startAnimationSvg, {
            opacity: 0,
            duration: 1
        }, '<+0.5')

        startTl.to('.header', {
            opacity: 1
        }, '>-0.3')

        startTl.to(mainBlock, {
            marginTop: document.querySelector('.header').offsetHeight,
            top: 0,
            transform: 'translateY(0%)'
        }, '<')

        startTl.to(mainBlockVideo, {
            borderRadius: `0 ${window.innerHeight * 60 / 1400}px 0 ${window.innerHeight * 60 / 1400}px`,
        }, '>')

        startTl.to(mainBlockVideo, {
            height: 'auto',
        }, '<')


        startTl.to(mainBlockWrapper, {
            opacity: 1
        }, '<')

        startTl.to([content, body], {
            height: 'auto',
            overflow: 'auto',
            duration: 0
        }, '>')
    } else {
        startTl.to(startAnimationSvg, {
            scale: 1,
            duration: 1.5,
            ease: 'power1.inOut'
        })

        startTl.to(mainBlockVideo, {
            opacity: 1,
            duration: 1,
            onComplete: () => {
                document.querySelector(".main-block video").play();
            }
        }, '>')

        startTl.to(startAnimationSvg, {
            opacity: 0,
            duration: 1
        }, '<+0.5')

        startTl.to('.header', {
            opacity: 1
        }, '>-0.3')

        startTl.to(mainBlock, {
            marginTop: document.querySelector('.header').offsetHeight,
        }, '<')

        startTl.to(mainBlockVideo, {
            borderRadius: '0 120px 0 120px',
        }, '>')

        startTl.to(mainBlock, {
            height: 700
        }, '>-0.3')

        startTl.to(mainBlockWrapper, {
            opacity: 1
        }, '<')

        startTl.to([content, body], {
            height: 'auto',
            overflow: 'auto',
        }, '<')

    }
})

// Next step animation

let nextStepTl = gsap.timeline()
const nextStepFromAnimation = document.querySelectorAll('#next-step_svg path')
const nextStepToAnimation = document.querySelectorAll('#foot_svg polygon')
const nextStepToMorphAnimation = document.querySelectorAll('#next-step_svg-from path')
for (let index = 0; index < nextStepToAnimation.length; index++) {
    nextStepTl.to(nextStepToMorphAnimation[index], {
        duration: 0.35,
        fill: `${getComputedStyle(nextStepFromAnimation[index]).getPropertyValue("fill")}`,
        transform: "translate(-55.64px , -642.21px)",
        morphSVG: nextStepFromAnimation[index],
    }, '<+0.025')
}
gsap.to('#next-step_svg-from', {
    scale: 1,
    rotate: 0
})
ScrollTrigger.create({
    trigger: '.next-step-bar__image',
    start: () => {
        if (window.innerWidth <= 900) {
            return "top bottom+=100"
        } else {
            return "top center"
        }
    },
    end: "bottom center",
    onToggle: (self) => {
        nextStepTl.clear()

        if (self.isActive) {
            for (let index = 0; index < nextStepToAnimation.length; index++) {
                nextStepTl.to(nextStepToMorphAnimation[index], {
                    duration: 0.35,
                    fill: `${getComputedStyle(nextStepToAnimation[index]).getPropertyValue("fill")}`,
                    morphSVG: nextStepToAnimation[index],
                    transform: "translate(0px,  0px)"
                }, '<+0.025')
            }

            gsap.to('#next-step_svg-from', {
                scale: 1.5,
                rotate: 45
            })
        } else {
            for (let index = 0; index < nextStepToAnimation.length; index++) {
                nextStepTl.to(nextStepToMorphAnimation[index], {
                    duration: 0.35,
                    fill: `${getComputedStyle(nextStepFromAnimation[index]).getPropertyValue("fill")}`,
                    transform: "translate(-55.64px , -642.21px)",
                    morphSVG: nextStepFromAnimation[index],
                }, '<+0.025')
            }
            gsap.to('#next-step_svg-from', {
                scale: 1,
                rotate: 0
            })
        }

    }
})


// Video mute/unmute

const buttonOn = document.querySelector('.main-block__volume-on')
const buttonOff = document.querySelector('.main-block__volume-off')

const mainVideo = document.querySelector('.main-block video')

// Umnute

buttonOn.addEventListener('click', () => {
    mainVideo.muted = false;
})

buttonOff.addEventListener('click', () => {
    mainVideo.muted = true;
})


// Employye bar animation

const employeeBarAnimation = document.querySelector('#employee-bar__animation-fixed')


ScrollTrigger.create({
    trigger: '.employee-bar',
    start: "top bottom-=500",
    end: "bottom top",
    onToggle: (self) => {
        if (self.isActive) {
            gsap.to(employeeBarAnimation, {
                display: 'block'
            })

            ScrollTrigger.create({
                trigger: '.employee-bar__animation-1-hidden',
                start: "top center-=200",
                end: "bottom center",
                onToggle: (self) => {
                    if (self.isActive) {
                        gsap.to('#employee-bar__animation-1-top-text', {
                            left: window.innerWidth <= 900 ? "15px" : '50px',
                            duration: 0.5
                        })

                        gsap.to('#employee-bar__animation-1-bottom-text', {
                            right: window.innerWidth <= 900 ? `${window.innerWidth / 10}px` : '50px',
                            duration: 0.5
                        })

                        gsap.to('#employee-bar__animation-1-image', {
                            opacity: 1,
                            duration: 0.5
                        })
                    } else {
                        gsap.to('#employee-bar__animation-1-top-text', {
                            left: '-300vw',
                            duration: 0.5
                        })

                        gsap.to('#employee-bar__animation-1-bottom-text', {
                            right: '-300vw',
                            duration: 0.5
                        })

                        gsap.to('#employee-bar__animation-1-image', {
                            opacity: 0,
                            duration: 0.5
                        })
                    }

                },
            });



            ScrollTrigger.create({
                trigger: '.employee-bar__animation-2-hidden',
                start: "top center",
                end: "bottom center",
                onToggle: (self) => {
                    if (self.isActive) {


                        gsap.to('#employee-bar__animation-2-top-text', {
                            left: window.innerWidth <= 900 ? "15px" : '50px',
                            duration: 0.5
                        })

                        gsap.to('#employee-bar__animation-2-bottom-text', {
                            right: window.innerWidth <= 900 ? `${window.innerWidth / 10}px` : '50px',
                            duration: 0.5
                        })

                        gsap.to('#employee-bar__animation-2-image', {
                            opacity: 1,
                            duration: 0.5
                        })

                        // employeeBarAnimation.style.position = 'fixed'
                    } else {
                        gsap.to('#employee-bar__animation-2-top-text', {
                            left: '-300vw',
                            duration: 0.5
                        })

                        gsap.to('#employee-bar__animation-2-bottom-text', {
                            right: '-300vw',
                            duration: 0.5
                        })

                        gsap.to('#employee-bar__animation-2-image', {
                            opacity: 0,
                            duration: 0.5
                        })
                    }

                },
            });


            ScrollTrigger.create({
                trigger: '.employee-bar__animation-3-hidden',
                start: "top center",
                end: "bottom center",
                onToggle: (self) => {
                    if (self.isActive) {


                        gsap.to('#employee-bar__animation-3-top-text', {
                            left: window.innerWidth <= 900 ? "15px" : '50px',
                            duration: 0.5
                        })

                        gsap.to('#employee-bar__animation-3-bottom-text', {
                            right: window.innerWidth <= 900 ? `${window.innerWidth / 10}px` : '50px',
                            duration: 0.5
                        })

                        gsap.to('#employee-bar__animation-3-image', {
                            opacity: 1,
                            duration: 0.5
                        })

                        // employeeBarAnimation.style.position = 'fixed'
                    } else {
                        gsap.to('#employee-bar__animation-3-top-text', {
                            left: '-300vw',
                            duration: 0.5
                        })

                        gsap.to('#employee-bar__animation-3-bottom-text', {
                            right: '-300vw',
                            duration: 0.5
                        })

                        gsap.to('#employee-bar__animation-3-image', {
                            opacity: 0,
                            duration: 0.5
                        })
                    }

                },
            });

            ScrollTrigger.create({
                trigger: '.employee-bar__animation-4-hidden',
                start: "top center",
                end: "bottom top",
                onToggle: (self) => {
                    if (self.isActive) {

                        gsap.to('#employee-bar__animation-4-bottom-text', {
                            right: window.innerWidth <= 900 ? `${window.innerWidth / 10}px` : '50px',
                            duration: 0.5
                        })

                        gsap.to('#employee-bar__animation-4-image', {
                            opacity: 1,
                            duration: 0.5
                        })

                        // employeeBarAnimation.style.position = 'fixed'
                    } else {

                        gsap.to('#employee-bar__animation-4-bottom-text', {
                            right: '-300vw',
                            duration: 0.5
                        })

                        gsap.to('#employee-bar__animation-4-image', {
                            opacity: 0,
                            duration: 0.5
                        })
                    }

                },
            });

        } else {
            gsap.to(employeeBarAnimation, {
                display: 'none'
            })
        }

    },
});



// Main scroll animation
gsap.registerPlugin(MorphSVGPlugin);
gsap.registerPlugin(SplitText)

const defaultPolygon = document.querySelector("#to-hide")

const tl = gsap.timeline();
const finalMainAnimation = gsap.timeline()


function toMorph(image, showAnimation) {

    tl.clear(true)
    finalMainAnimation.clear(true)


    const morphFromSvg = document.querySelector('#morph-from svg')
    const morphFromPolygonsArray = morphFromSvg.querySelectorAll('path')
    let animationDuration = 0.35

    gsap.set(morphFromPolygonsArray, { x: 0, y: 0 })
    gsap.set('#morph-from svg', { rotate: 0 })

    let polygonToMorph = 0

    let imagePolygonArray = image.querySelectorAll('polygon')


    if (imagePolygonArray.length == 0) {
        imagePolygonArray = image.querySelectorAll('path')
    }
    for (let index = morphFromPolygonsArray.length - 1; index >= 0; index -= 1) {
        if (imagePolygonArray[polygonToMorph]) {
            tl.to(morphFromPolygonsArray[index], {
                duration: animationDuration,
                fill: `${getComputedStyle(imagePolygonArray[polygonToMorph]).getPropertyValue("fill")}`,
                morphSVG: imagePolygonArray[polygonToMorph],
                onComplete: () => {
                    if (!imagePolygonArray[morphFromPolygonsArray.length - index]) {
                        if (image == document.querySelector('#bottom-buttle')) {

                            setTimeout(() => {

                                finalMainAnimation.to('#morph-from svg', {
                                    duration: 0.1,
                                    rotate: 5,
                                })

                                finalMainAnimation.to('#morph-from svg', {
                                    duration: 0.1,
                                    rotate: -5,
                                })

                                finalMainAnimation.to('#morph-from svg', {
                                    duration: 0.1,
                                    rotate: 5,
                                })

                                finalMainAnimation.to('#morph-from svg', {
                                    duration: 0.1,
                                    rotate: -5,
                                })

                                finalMainAnimation.to('#morph-from svg', {
                                    duration: 0.1,
                                    rotate: 5,
                                })

                                finalMainAnimation.to('#morph-from svg', {
                                    duration: 0.1,
                                    rotate: -5,
                                })

                                finalMainAnimation.to('#morph-from svg', {
                                    duration: 0.1,
                                    rotate: 5,
                                })

                                finalMainAnimation.to('#morph-from svg', {
                                    duration: 0.1,
                                    rotate: 0,
                                })

                                finalMainAnimation.to(['#morph-from-path-17', '#morph-from-path-22', '#morph-from-path-26'], {
                                    x: 250,
                                    y: -250,
                                })
                            }, 0);
                        }
                    }
                }
            }, '<+0.025');

        } else {
            tl.to(morphFromPolygonsArray[index], {
                duration: animationDuration,
                fill: `${getComputedStyle(defaultPolygon).getPropertyValue("fill")}`,
                morphSVG: defaultPolygon,
            }, '<');
        }
        polygonToMorph++
    }

}


ScrollTrigger.create({
    trigger: ".all-main-animation-on-scroll",
    start: () => {
        if (window.innerWidth <= 767) {
            return "top top-=100"
        } else {
            return "top center"
        }
    },
    end:
        () => {
            if (window.innerWidth <= 767) {
                return "bottom center+=100"
            } else {
                return "bottom center"
            }
        },

    onToggle: (self) => {
        if (self.isActive) {
            ScrollTrigger.create({
                trigger: "#text_1",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top-=150"
                    } else {
                        return "top center"
                    }
                },
                end:
                    () => {
                        if (window.innerWidth <= 767) {
                            return "bottom center-=300"
                        } else {
                            return "bottom 50%+=100px"
                        }
                    },
                onToggle: (self) => {

                    if (self.isActive) {
                        toMorph(document.querySelector('#bird'))
                        // Change sides

                        if (window.innerWidth <= 767) {
                            document.querySelector('.animation_wrapper').style.left = `25%`
                            document.querySelector('.animation_wrapper').style.width = `50%`
                            document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                            document.querySelector('.animation_wrapper').style.minWidth = `50%`
                            document.querySelector('.animation_wrapper').style.height = `auto`
                            document.querySelector('.animation_wrapper').style.top = `25px`
                        } else {
                            document.querySelector('.animation_wrapper').style.left = `${(window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2}px`
                        }
                    } else {
                        toMorph(document.querySelector('#to-be-here'))

                    }


                },
            });

            ScrollTrigger.create({
                trigger: "#text_2",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top"
                    } else {
                        return "top center"
                    }
                },
                end: "bottom 50%+=100px",
                onToggle: (self) => {
                    toMorph(document.querySelector('#rebit'), 1500)

                    // Change sides
                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `25px`
                    } else {
                        document.querySelector('.animation_wrapper').style.left = `${(window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2}px`
                    }
                },
            });

            ScrollTrigger.create({
                trigger: "#text_3",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top"
                    } else {
                        return "top center"
                    }
                },
                end: () => {
                    if (window.innerWidth <= 767) {
                        return "bottom 50%+=300px"
                    } else {
                        return "bottom 50%+=500px"
                    }
                },
                onToggle: (self) => {
                    toMorph(document.querySelector('#squer'))
                    gsap.to('.text_3-image', {
                        opacity: 0,
                        duration: 0.5
                    })
                    // Change sides

                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `25px`
                    } else {

                        let padding = window.innerWidth - ((window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2) - document.querySelector('.animation_wrapper').getBoundingClientRect().width
                        document.querySelector('.animation_wrapper').style.left = `${padding}px`
                    }
                },
            });

            if (window.innerWidth >= 767) {
                ScrollTrigger.create({
                    trigger: "#text_3",
                    start: "bottom 50%+=500px",
                    end: "bottom 50%+=100px",
                    onToggle: (self) => {
                        toMorph(document.querySelector('#to-be-here'))
                        gsap.to('.text_3-image', {
                            opacity: 1,
                            duration: 0.5
                        })

                        if (window.innerWidth <= 767) {
                            document.querySelector('.animation_wrapper').style.left = `25%`
                            document.querySelector('.animation_wrapper').style.width = `50%`
                            document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                            document.querySelector('.animation_wrapper').style.minWidth = `50%`
                            document.querySelector('.animation_wrapper').style.height = `auto`
                            document.querySelector('.animation_wrapper').style.top = `25px`
                        } else {

                            let padding = window.innerWidth - ((window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2) - document.querySelector('.animation_wrapper').getBoundingClientRect().width
                            document.querySelector('.animation_wrapper').style.left = `${padding}px`
                        }
                    }
                });
            }


            ScrollTrigger.create({
                trigger: "#text_4",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top"
                    } else {
                        return "top center"
                    }
                },
                end:
                    () => {
                        if (window.innerWidth <= 767) {
                            return "top top-=200px"
                        } else {
                            return "bottom 50%+=300px"
                        }
                    },
                onToggle: (self) => {
                    toMorph(document.querySelector('#cube-1'))

                    // Change sides
                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `25px`
                    } else {
                        document.querySelector('.animation_wrapper').style.left = `${(window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2}px`
                    }
                },
            });

            ScrollTrigger.create({
                trigger: "#text_4",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top-=200px"
                    } else {
                        return "bottom 50%+=300px"
                    }
                },
                end: "bottom 50%+=100px",
                onToggle: (self) => {
                    toMorph(document.querySelector('#cube-2'))

                    // Change sides
                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `25px`
                    } else {
                        document.querySelector('.animation_wrapper').style.left = `${(window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2}px`
                    }
                },
            });

            ScrollTrigger.create({
                trigger: "#text_6",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top"
                    } else {
                        return "top center-=100"
                    }
                },
                end: "bottom 50%+=100px",
                onToggle: (self) => {
                    toMorph(document.querySelector('#window'))

                    // Change sides

                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `25px`
                    } else {

                        let padding = window.innerWidth - ((window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2) - document.querySelector('.animation_wrapper').getBoundingClientRect().width
                        document.querySelector('.animation_wrapper').style.left = `${padding}px`
                    }
                },
            });

            ScrollTrigger.create({
                trigger: "#text_7",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top"
                    } else {
                        return "top center"
                    }
                },
                end: "bottom 50%+=100px",
                onToggle: (self) => {
                    toMorph(document.querySelector('#sphere'))
                    gsap.to('.text_12-image', {
                        opacity: 0,
                        duration: 0.5
                    })
                    // Change sides

                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `25px`
                    } else {

                        let padding = window.innerWidth - ((window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2) - document.querySelector('.animation_wrapper').getBoundingClientRect().width
                        document.querySelector('.animation_wrapper').style.left = `${padding}px`
                    }
                },
            });


            ScrollTrigger.create({
                trigger: "#text_12",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top"
                    } else {
                        return "top center"
                    }
                },
                end: "bottom 50%+=100px",
                onToggle: (self) => {
                    toMorph(document.querySelector('#to-be-here'))

                    gsap.to('.text_12-image', {
                        opacity: 1,
                        duration: 1
                    })

                    // Change sides
                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `25px`
                    } else {
                        document.querySelector('.animation_wrapper').style.left = `${(window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2}px`
                    }
                },
            });


            ScrollTrigger.create({
                trigger: "#text_8",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top"
                    } else {
                        return "top center"
                    }
                },
                end: "bottom 50%+=100px",
                onToggle: (self) => {
                    toMorph(document.querySelector('#heart'), 1500)
                    gsap.to('.text_12-image', {
                        opacity: 0,
                        duration: 0.5
                    })
                    // Change sides
                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `25px`
                    } else {
                        document.querySelector('.animation_wrapper').style.left = `${(window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2}px`
                    }
                },
            });


            ScrollTrigger.create({
                trigger: "#text_9",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top"
                    } else {
                        return "top center"
                    }
                },
                end: "bottom 50%+=100px",
                onToggle: (self) => {
                    toMorph(document.querySelector('#circle_arrow'), 1500)

                    // Change sides
                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `25px`
                    } else {
                        document.querySelector('.animation_wrapper').style.left = `${(window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2}px`
                    }
                },
            });


            ScrollTrigger.create({
                trigger: "#text_10",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top"
                    } else {
                        return "top center"
                    }
                },
                end: "bottom center",
                onToggle: (self) => {
                    toMorph(document.querySelector('#arrow-up'))

                    // Change sides

                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `25px`
                    } else {

                        let padding = window.innerWidth - ((window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2) - document.querySelector('.animation_wrapper').getBoundingClientRect().width
                        document.querySelector('.animation_wrapper').style.left = `${padding}px`
                    }
                },
            });

            ScrollTrigger.create({
                trigger: "#text_11",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top"
                    } else {
                        return "top center"
                    }
                },
                end: () => {
                    if (window.innerWidth <= 767) {
                        return "bottom 50%-=200"
                    } else {
                        return "bottom 50%+=200"
                    }
                },
                onToggle: (self) => {
                    toMorph(document.querySelector('#bottom-buttle'))

                    // Change sides
                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `25px`
                    } else {
                        document.querySelector('.animation_wrapper').style.left = `${(window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2}px`
                    }

                    if (self.isActive) {
                        toMorph(document.querySelector('#bottom-buttle'))
                    }
                    else if (!self.isActive) {
                        toMorph(document.querySelector('#to-be-here'))
                    }
                },
            });
        } else {
            toMorph(document.querySelector('#to-be-here'))
        }

    }
})

// Grey images animtion

ScrollTrigger.create({
    trigger: ".text-info-bar",
    start: "top bottom",
    end: "bottom top",
    onToggle: (self) => {
        if (self.isActive) {
            if (window.innerWidth <= 900) {
                gsap.to('.header', {
                    opacity: 0,
                    duration: 0.5
                })
            }

            ScrollTrigger.create({
                trigger: '#text-info-bar__item-1',
                start: "top top+=300",
                end: "bottom top+=300",
                onToggle: (self) => {

                    if (self.isActive) {
                        gsap.to('#text-info-bar__image-1', {
                            left: window.innerWidth <= 900 ?
                                "50%" :
                                (window.innerWidth - document.querySelector('.container').offsetWidth) / 2,
                        })
                    } else {
                        gsap.to('#text-info-bar__image-1', {
                            left: '-100%',
                        })
                    }
                },
            });

            ScrollTrigger.create({
                trigger: '#text-info-bar__item-2',
                start: "top top+=300",
                end: "bottom top+=300",
                onToggle: (self) => {

                    if (self.isActive) {
                        gsap.to('#text-info-bar__image-2', {
                            left: window.innerWidth <= 900 ?
                                "50%" :
                                (window.innerWidth - document.querySelector('.container').offsetWidth) / 2,
                        })
                    } else {
                        gsap.to('#text-info-bar__image-2', {
                            left: '-100%',
                        })
                    }
                },
            });

            ScrollTrigger.create({
                trigger: '#text-info-bar__item-3',
                start: "top top+=300",
                end: "bottom top+=300",
                onToggle: (self) => {

                    if (self.isActive) {
                        gsap.to('#text-info-bar__image-3', {
                            left: window.innerWidth <= 900 ?
                                "50%" :
                                (window.innerWidth - document.querySelector('.container').offsetWidth) / 2,
                        })
                    } else {
                        gsap.to('#text-info-bar__image-3', {
                            left: '-100%',
                        })
                    }
                },
            });

            ScrollTrigger.create({
                trigger: '#text-info-bar__item-4',
                start: "top top+=300",
                end: "bottom top+=300",
                onToggle: (self) => {

                    if (self.isActive) {
                        gsap.to('#text-info-bar__image-4', {
                            left: window.innerWidth <= 900 ?
                                "50%" :
                                (window.innerWidth - document.querySelector('.container').offsetWidth) / 2,
                        })
                    } else {
                        gsap.to('#text-info-bar__image-4', {
                            left: '-100%',
                        })
                    }
                },
            });

            ScrollTrigger.create({
                trigger: '#text-info-bar__item-5',
                start: "top top+=300",
                end: "bottom top+=300",
                onToggle: (self) => {

                    if (self.isActive) {
                        gsap.to('#text-info-bar__image-5', {
                            left: window.innerWidth <= 900 ?
                                "50%" :
                                (window.innerWidth - document.querySelector('.container').offsetWidth) / 2,
                        })
                    } else {
                        gsap.to('#text-info-bar__image-5', {
                            left: '-100%',
                        })
                    }
                },
            });


        } else {
            if (window.innerWidth <= 900) {
                gsap.to('.header', {
                    opacity: 1,
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    duration: 0
                })
            }
        }
    },
});


// Larg info animation
const largeInfoItems = document.querySelectorAll('.lagre-info-block__item')

largeInfoItems.forEach(item => {
    ScrollTrigger.create({
        trigger: item,
        start: "top center+=200px",
        onToggle: (self) => {
            gsap.to(item, {
                opacity: 1,
                duration: 1
            });
        },
    });
})

// Number counter
let numberCounterTimeline1 = gsap.timeline()
let numberCounterTimeline2 = gsap.timeline()
let numberCounterTimeline3 = gsap.timeline({ repeat: -1, repeatDelay: 1 })

ScrollTrigger.create({
    trigger: "#number-1-animation",
    start: "top bottom",
    end: "bottom top-=100",
    onToggle: (self) => {
        if (self.isActive) {
            numberCounterTimeline1.clear(true)

            numberCounterTimeline1.to('#number-1-animation', {
                duration: 2,
                innerHTML: 30,
                snap: 'innerHTML',
                repeat: -1,
                repeatDelay: 2
            });
        } else {
            numberCounterTimeline1.clear(true)
            gsap.set('#number-1-animation', {
                duration: 0,
                innerHTML: 0,
                snap: 'innerHTML'
            });
        }
    },
});


ScrollTrigger.create({
    trigger: "#number-2-animation",
    start: "top bottom",
    end: "bottom top-=100",
    onToggle: (self) => {

        if (self.isActive) {
            numberCounterTimeline2.clear(true)
            numberCounterTimeline2.to('#number-2-animation', {
                duration: 2,
                innerHTML: 40,
                snap: 'innerHTML',
                repeat: -1,
                repeatDelay: 2
            });
        } else {
            numberCounterTimeline2.clear(true)
            gsap.set('#number-2-animation', {
                innerHTML: 0,
                snap: 'innerHTML'
            });
        }

    },
});

let currentValue = 0;

ScrollTrigger.create({
    trigger: "#number-3-animation",
    start: "top bottom",
    end: "bottom top-=100",
    onToggle: (self) => {

        if (self.isActive) {
            numberCounterTimeline3.clear(true)
            numberCounterTimeline3.to('#number-3-animation', {
                duration: 3,
                innerHTML: 99,
                snap: 'innerHTML',
            })

            numberCounterTimeline3.to('#number-3-animation-wrapper', {
                opacity: 0
            }, '>-2')
            numberCounterTimeline3.to('#number-4-animation-wrapper', {
                opacity: 1
            }, '<');
        } else {
            numberCounterTimeline3.clear(true)
            gsap.set('#number-3-animation', {
                duration: 0,
                innerHTML: 0,
                snap: 'innerHTML'
            })

            gsap.set('#number-3-animation-wrapper', {
                opacity: 1
            }, '>-1')
            gsap.set('#number-4-animation-wrapper', {
                opacity: 0
            }, '<');
        }

    },
});



// Start ScrollTrigger
ScrollTrigger.create({
    trigger: ".all-main-animation-on-scroll",
    start: "top bottom-=350px",
    end: () => {
        if (window.innerHeight >= 1000) {
            return "bottom 100%"
        } else {
            return "bottom 50%+=100"
        }
    },
    onToggle: (self) => {
        const animationWrapper = document.querySelector('.animation_wrapper');
        if (self.isActive) {
            animationWrapper.style.opacity = `1`;

            if (window.innerWidth <= 900) {
                gsap.to('.header', {
                    opacity: 0,
                    duration: 0.5
                })
            }

        } else {
            animationWrapper.style.opacity = `0`;
            if (window.innerWidth <= 900) {
                gsap.to('.header', {
                    opacity: 1,
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    duration: 0
                })
            }
        }
    },
});


// Bubbles animations
const bubbleTl = gsap.timeline().timeScale(1.5)

let bubbles = document.querySelector('#top-bar-animation-item-bubble')
let bubblesSvgPaths = bubbles.querySelectorAll('svg path')

bubbles.addEventListener('mouseenter', () => {
    bubbleTl.to(bubblesSvgPaths[1], {
        duration: 1,
        y: 15,
        x: -13,
        yoyo: true,
        repeat: -1,
        ease: 'linear'
    })

    bubbleTl.to(bubblesSvgPaths[0], {
        duration: 1.3,
        y: -15,
        x: -13,
        yoyo: true,
        repeat: -1,
        ease: 'linear'
    }, '<')

    bubbleTl.to([bubblesSvgPaths[3], bubblesSvgPaths[2]], {
        duration: 1,
        y: -40,
        yoyo: true,
        repeat: -1,
        ease: 'linear'

    }, '<')
})

bubbles.addEventListener('mouseleave', () => {
    bubbleTl.clear(true)
    gsap.to(bubblesSvgPaths, {
        y: 0,
    })
})

// Heart animations

const heartTl = gsap.timeline().timeScale(1.5)

let heart = document.querySelector('#top-bar-animation-item-heart')
let heartSvg = document.querySelector('#top-bar-animation-item-heart svg')

heart.addEventListener('mouseenter', () => {
    heartTl.to(heartSvg, {
        duration: 0.3,
        scale: 1.08,
        repeat: -1,
        repeatDelay: 1.3
    })
    heartTl.to(heartSvg, {
        duration: 0.3,
        scale: 1,
        repeat: -1,
        repeatDelay: 1.3
    })
    heartTl.to(heartSvg, {
        duration: 0.3,
        scale: 1.09,
        repeat: -1,
        repeatDelay: 1.3
    })

    heartTl.to(heartSvg, {
        duration: 0.3,
        scale: 1,
        repeat: -1,
        repeatDelay: 1.3
    })
})

heart.addEventListener('mouseleave', () => {
    heartTl.clear(true)
    gsap.to(heartSvg, {
        scale: 1,
    })
})

// Bottle animations
const bottleTl = gsap.timeline()

let bottle = document.querySelector('#top-bar-animation-item-bottle')
let bottleSvg = document.querySelector('#top-bar-animation-item-bottle svg')

bottle.addEventListener('mouseenter', () => {
    bottleTl.to(bottleSvg, {
        duration: 0.1,
        rotate: 7,
        repeat: -1,
        repeatDelay: 0.4
    })

    bottleTl.to(bottleSvg, {
        duration: 0.1,
        rotate: -7,
        repeat: -1,
        repeatDelay: 0.4
    })

    bottleTl.to(bottleSvg, {
        duration: 0.1,
        rotate: 7,
        repeat: -1,
        repeatDelay: 0.4
    })

    bottleTl.to(bottleSvg, {
        duration: 0.1,
        rotate: 0,
        repeat: -1,
        repeatDelay: 0.4
    })

})

bottle.addEventListener('mouseleave', () => {
    bottleTl.clear(true)
    gsap.to(bottleSvg, {
        scale: 1,
    })
})


// Rock animations
const rockTl = gsap.timeline().timeScale(1.2)

let rock = document.querySelector('#top-bar-animation-item-rock')
let rockSvg = document.querySelector('#top-bar-animation-item-rock svg')

rock.addEventListener('mouseenter', () => {
    rockTl.to(rockSvg, {
        duration: 0.15,
        x: 10,
        y: 7,
        rotate: 5,
        repeat: -1,
        repeatDelay: 0.55
    })

    rockTl.to(rockSvg, {
        duration: 0.15,
        x: -15,
        y: -20,
        rotate: -10,
        repeat: -1,
        repeatDelay: 0.55
    })

    rockTl.to(rockSvg, {
        duration: 0.15,
        x: 15,
        y: 0,
        rotate: 5,
        repeat: -1,
        repeatDelay: 0.55
    })

    rockTl.to(rockSvg, {
        duration: 0.15,
        x: 0,
        y: 0,
        rotate: 0,
        repeat: -1,
        repeatDelay: 0.55
    })

})

rock.addEventListener('mouseleave', () => {
    rockTl.clear(true)
    gsap.to(rockSvg, {
        x: 0,
        y: 0
    })
})


// Bird animations

const birdTl = gsap.timeline().timeScale(1.2)
const birdTl1 = gsap.timeline().timeScale(1.2)
const birdTl2 = gsap.timeline()

let bird = document.querySelector('#top-bar-animation-item-bird')
let birdSvg = document.querySelector('#top-bar-animation-item-bird svg')
let birdSvgW1 = document.querySelectorAll('#top-bar-animation-item-bird-w-1')
let birdSvgW2 = document.querySelector('#top-bar-animation-item-bird-w-2')

bird.addEventListener('mouseenter', () => {
    birdTl.to(birdSvgW2, {
        duration: 0.3,
        scaleX: 0.5,
        scaleY: 1.2,
        x: 150,
        y: -55,
        rotate: 9,
        repeat: -1,
        repeatDelay: 0.3,
        delay: 0.3
    })

    birdTl.to(birdSvgW2, {
        duration: 0.3,
        scaleX: 1,
        scaleY: 1,
        x: 0,
        y: 0,
        rotate: 0,
        repeat: -1,
        repeatDelay: 0.3
    })


    birdTl1.to(birdSvgW1, {
        duration: 0.3,
        scaleX: 0.5,
        scaleY: 1,
        x: 135,
        y: -15,
        rotate: 7,
        repeat: -1,
        repeatDelay: 0.3

    })

    birdTl1.to(birdSvgW1, {
        duration: 0.3,
        scaleX: 1,
        scaleY: 1,
        x: 0,
        y: 0,
        rotate: 0,
        repeat: -1,
        repeatDelay: 0.3
    })

    birdTl2.to(birdSvg, {
        y: -20,
        duration: 0.5,
        yoyo: true,
        repeat: -1,
        ease: 'power1.inOut'
    })

})

bird.addEventListener('mouseleave', () => {
    birdTl.clear(true)
    birdTl1.clear(true)
    birdTl2.clear(true)
    gsap.to(birdSvgW1, {
        scaleX: 1,
        scaleY: 1,
        x: 0,
        y: 0,
        rotate: 0,
    })
    gsap.to(birdSvgW2, {
        scaleX: 1,
        scaleY: 1,
        x: 0,
        y: 0,
        rotate: 0,
    })
    gsap.to(birdSvg, {
        y: 0,
        duration: 0.5,
        ease: 'power1.inOut'
    })
})

// Arrow animations

const arrowTl = gsap.timeline()

let arrow = document.querySelector('#top-bar-animation-item-arrow')
let arrowSvg = document.querySelector('#top-arrow-head')
// gsap.set(arrowSvg, {
//     x: 0,
//     y: 115,
//     scale: 0.7,
//     repeat: -1,
//     duration: 0.3,
//     repeatDelay: 2
// })

arrow.addEventListener('mouseenter', () => {

    arrowTl.to(arrowSvg, {
        x: 0,
        y: 78,
        scale: 0.8,
        repeat: -1,
        duration: 0.5,
        repeatDelay: 2
    })

    arrowTl.to(arrowSvg, {
        x: 0,
        y: 0,
        scale: 1,
        repeat: -1,
        duration: 0.5,
        repeatDelay: 2
    })

    arrowTl.to(arrowSvg, {
        x: 0,
        y: 78,
        scale: 0.8,
        repeat: -1,
        duration: 0.5,
        repeatDelay: 2
    })

    arrowTl.to(arrowSvg, {
        x: 0,
        y: 0,
        scale: 1,
        repeat: -1,
        duration: 0.5,
        repeatDelay: 2
    })

    // arrowTl.to(arrowSvg, {
    //     x: -45,
    //     y: 15,
    //     repeat: -1,
    //     duration: 0.3,
    //     repeatDelay: 2
    // })

    // arrowTl.to(arrowSvg, {
    //     x: -20,
    //     y: 8,
    //     repeat: -1,
    //     duration: 0.3,
    //     repeatDelay: 2
    // })

    // arrowTl.to(arrowSvg, {
    //     x: -90,
    //     y: 30,
    //     repeat: -1,
    //     duration: 0.3,
    //     repeatDelay: 2
    // })

    // arrowTl.to(arrowSvg, {
    //     x: 0,
    //     y: 0,
    //     repeat: -1,
    //     duration: 0.3,
    //     repeatDelay: 2
    // })

})

arrow.addEventListener('mouseleave', () => {
    arrowTl.clear(true)
    gsap.to(arrowSvg, {
        scale: 1,
        y: 0,
        x: 0
    })
})
